<template>
  <v-col cols="11">
    <span class="text-h4">Message History</span>
    <v-skeleton-loader
      class="ma-5"
      v-if="loading"
      type="list-item-two-line, divider, list-item-two-line"
    ></v-skeleton-loader>
    <template v-else-if="items.length > 0" v-for="(item, index) in items">
      <v-card :key="index">
        <v-card-text class="text--primary mt-4" v-html="item.Messages"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="overline text--secondary">
            <span class="text--primary">{{ item.AdminNM }}</span>
            {{ item.ADD_DATE }}
          </span>
        </v-card-actions>
      </v-card>
    </template>
    <template v-else>
      <v-card>
        <v-card-text class="text--primary mt-4 text--disabled"
          >No messages for the past 7 days</v-card-text
        >
      </v-card>
    </template>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      items: []
    };
  },
  created() {
    this.loading = true;
    this.$axios
      .get("/telemarketer/message/history")
      .then((res) => {
        this.items = res.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>
